/* eslint-disable */
/** @prettier */
/*========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
========================================================================================*/

const actions = {
  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  updateSidebarWidth({ commit }, width) {
    commit('UPDATE_SIDEBAR_WIDTH', width);
  },
  updateI18nLocale({ commit }, locale) {
    commit('UPDATE_I18N_LOCALE', locale);
  },
  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY');
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val);
  },

  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload);
  },


  record_event({ commit, state }, payload) {

    var input_sql = {};
    var axios_var_in = {};

    console.log(' inside record_event ^ session_status ' + state.session_status)

    commit('get_os', '');
    commit('get_browser', payload.browser_var);
    commit('get_url', '');


    if (state.session_status === 0) {

      console.log('inside if session_status = 0');




      if (payload.cookies_var.isKey('anonymous_hash')){

        commit('update_anonymous_hash',payload.cookies_var.get('anonymous_hash'));
        commit('update_anonymous_id',payload.cookies_var.get('anonymous_id'));

        input_sql = {'browser':state.browser,'browser_name':state.browser_name,'browser_version':state.browser_version,'browser_ua':state.browser_ua,
          'os': state.os,'ip':state.ip,'page':state.page,'url':state.url,'user_id': state.user_id, 'session_hash': state.session_hash,
          'anonymous_id':state.anonymous_id,'anonymous_hash':state.anonymous_hash,'session_status': state.session_status,
          'actionType': payload.actionType, 'clickedObjectId': payload.clickedObjectId};

        axios_var_in = {'axios_var': payload.axios_var, 'root_var': payload.root_var, 'input_sql': input_sql};


        commit('record_session_event',axios_var_in);

      } else {

        payload.axios_var.get(payload.root_var+'/analytics/create-anonymous-user')
            .then( (res) => {
              payload.cookies_var.set('anonymous_hash',res.data.anonymous_hash);
              payload.cookies_var.set('anonymous_id',res.data.anonymous_id);
              commit('update_anonymous_hash',res.data.anonymous_hash);
              commit('update_anonymous_id',res.data.anonymous_id);

              input_sql = {'browser':state.browser,'browser_name':state.browser_name,'browser_version':state.browser_version,'browser_ua':state.browser_ua,
                'os': state.os,'ip':state.ip,'page':state.page,'url':state.url,'user_id': state.user_id, 'session_hash': state.session_hash,
                'anonymous_id':state.anonymous_id,'anonymous_hash':state.anonymous_hash,'session_status': state.session_status,
                'actionType': payload.actionType, 'clickedObjectId': payload.clickedObjectId};

              axios_var_in = {'axios_var': payload.axios_var, 'root_var': payload.root_var, 'input_sql': input_sql};

              commit('record_session_event',axios_var_in);

            });

      }

      commit('update_session_status', 1);

      console.log(' inside if ^ session_status ' + state.session_status)
      //console.log(input_sql)
    } else {

      console.log('inside else session_status ');
      console.log(' session_hash ' +state.session_hash);
      input_sql = {'browser':state.browser,'browser_name':state.browser_name,'browser_version':state.browser_version,'browser_ua':state.browser_ua,
        'os': state.os,'ip':state.ip,'page':state.page,'url':state.url,'user_id': state.user_id, 'session_hash': state.session_hash,
        'anonymous_id':state.anonymous_id,'anonymous_hash':state.anonymous_hash,'session_status': state.session_status,
        'actionType': payload.actionType, 'clickedObjectId': payload.clickedObjectId};

      axios_var_in = {'axios_var': payload.axios_var, 'root_var': payload.root_var, 'input_sql': input_sql};

      commit('record_session_event',axios_var_in);

      //console.log(input_sql)

    }


  }


};

export default actions;
