import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/store';


Vue.config.productionTip = true;
Vue.prototype.$hostname = Vue.config.productionTip
    ? 'https://skillrank.ru/api'
    : 'http://localhost:8079';

Vue.config.productionTipFront = true;
Vue.prototype.$hostnamefront = Vue.config.productionTipFront
    ? 'https://skillrank.ru'
    : 'http://localhost:8080';

Vue.config.productionTipWeaviate = true;
Vue.prototype.$hostnameweaviate = Vue.config.productionTipWeaviate
    ? 'https://skillrank.ru/weaviate-api'
    : 'http://localhost:7096';




import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
  duration: 3000,
  easing: "ease"
});

import VueSSE from 'vue-sse';
Vue.use(VueSSE);


import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios,axios)

import Notifications from 'vue-notification';
Vue.use(Notifications)

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

import VueQuillEditor from 'vue-quill-editor'


import '../public/css/quill.snow.css';

/*
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import Scrollspy from 'vue2-scrollspy';
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueMasonry from 'vue-masonry-css'

import "../public/css/materialdesignicons.min.css";
import 'swiper/swiper-bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'remixicon/fonts/remixicon.css'


Vue.use(Scrollspy);
Vue.use(VueYoutube)
Vue.use(vueVimeoPlayer)
Vue.use(VueMasonry)

import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css'
import 'vuesax/dist/vuesax.css'
Vue.use(Vuesax)

 */

/*"vuesax": "^4.0.1-alpha.25",*/


Vue.use(VueQuillEditor);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
