/* eslint-disable */
/** @prettier */
/*========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
========================================================================================*/

const getters = {
  // COMPONENT
  // vx-autosuggest

};

export default getters;
